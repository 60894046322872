<template>
  <List
    :title="'Equipment'"
    :entries="bodyItems"
    :headItems="headItems"
    :hasCheck="false"
    :canCreate="canCreate"
    :loaded="loaded"
    :toFilter="toFilter"
    :tabFilters="tabFilters"
    :isActive="isActive"
    @optionSel="handleOption"
    @create="handleCreate"
    @filters="filterByItem"
    @actions="handleAction"
    @search="handleSearch"
  />
  <Pagination
    :entries="entries"
    :currentPage="currentPage"
    :total="total"
    :perPage="perPage"
    :pages.="pages"
    @changePage="handlePageChange"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import axiosInstance from "@/services/AxiosTokenInstance";
import List from "@/components/List/Index.vue";
import Pagination from "@/components/List/Pagination.vue";
import timeSince from "@/mixins/timeSince";
import isMobile from "@/mixins/isMobile";
import api from "@/mixins/optionsApi";

export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },
  data() {
    return {
      title: "Equipments",
      entries: [],
      currentPage: 1,
      filteredEntries: [],
      total: 0,
      pages: 0,
      perPage: 10,
      imgURL: process.env.VUE_APP_DO_SPACES,
      loaded: false,
      filter: [],
      canCreate: [],
      headItems: ["Name", "Serial Number", "Sites"],
      bodyItems: [],
      editOptions: ["see", "edit"],
      search_key: "",
      hasOptions: true,
      see: false,
      editItem: false,
      create: false,
      selItem: 0,
      client: localStorage.getItem("client")
        ? JSON.parse(localStorage.getItem("client"))
        : null,
      tabFilters: [
        {
          name: "All Sites",
          filter: "",
          isActive: true,
        },
      ],
      isActive: 0,
    };
  },

  provide() {
    return {
      edit: this.editOptions,
      hasOptions: this.hasOptions,
    };
  },

  components: {
    List,
    Pagination,
  },

  mixins: [timeSince, isMobile, api],

  watch: {
    currentPage: {
      immediate: true,
      handler(val) {
        this.getData(val);
      },
    },

    filter() {
      this.getData(1);
    },

    entries() {
      this.fillBodyItems();
    },

    search_key() {
      this.getData(1);
    },
  },

  computed: {
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),
    currentTotal() {
      return this.total > 10 && this.perPage * this.currentPage < this.total
        ? this.perPage * this.currentPage
        : this.total;
    },
    toFilter() {
      return [
        {
          name: "sites",
          options: this.api("sites"),
        },
      ];
    },

    productsToSend() {
      let toReturn = [];
      const item = this.entries.find((el) => el.id === this.selItem) || {};
      const prod_names = item.product_list
        .filter((el) => el.products_details)
        .map((el) => el.products_details.name);
      prod_names.forEach((el) => toReturn.push({ name: el }));
      return toReturn;
    },
  },
  methods: {
    handlePageChange(to) {
      switch (to) {
        case "less":
          this.currentPage -= 1;
          break;
        case "more":
          this.currentPage += 1;
          break;
      }
    },

    fillBodyItems() {
      this.bodyItems = this.entries.map((item) => {
        return [
          {
            template: "image",
            id: item.id,
            data: {
              image: "none",
              title: item.name,
              description: [],
            },
          },
          {
            template: "normal",
            data: item.serial_no || "undefined",
          },
          {
            template: "normal",
            data: item.site?.site_details.name || "undefined",
          },
        ];
      });
    },

    async getData() {
      this.loaded = false;
      let orgDataString = localStorage.getItem("organization");
      this.entries = [];
      this.perPage = 0;
      this.total = 0;
      this.pages = 0;
      const client_id = this.client ? this.client.id : "";
      if (orgDataString) {
        let orgData = JSON.parse(orgDataString);
        let response;
        if (!this.filter.length)
          response = await axiosInstance
            .get(
              `equipments?org_id=${orgData.id}&searchKey=${this.search_key}&client_id=${client_id}&page=${this.currentPage}`
            )
            .finally(() => (this.loaded = true));
        else
          response = await axiosInstance
            .get(
              `equipments?org_id=${orgData.id}&searchKey=${this.search_key}&${this.filter[0]}=${this.filter[1]}&client_id=${client_id}&page=${this.currentPage}`
            )
            .finally(() => (this.loaded = true));
        let responseData = response.data.data;
        this.entries = responseData.data;
        this.perPage = Number(responseData.per_page);
        this.total = responseData.total;
        this.pages = responseData.last_page;
      }
    },

    async sites() {
      let orgData = JSON.parse(localStorage.getItem("organization"));
      const allSites = [];
      let lastPage = 1;

      const client_id = this.client ? this.client.id : "";
      const response = await axiosInstance.get(
        `new-site-list?org_id=${orgData.id}&clientId=${client_id}&limit=100`
      );
      lastPage = response.data.data.last_page;
      allSites.push(...response.data.data.data);

      if (lastPage >= 2) {
        for (let i = 2; i <= lastPage; i++) {
          const res = await axiosInstance.get(
            `new-site-list?org_id=${orgData.id}&clientId=${client_id}&limit=100&page=${i}`
          );
          allSites.push(...res.data.data.data);
        }
      }

      return (
        allSites.map((site) => {
          return { id: site.id, name: site.name, image: null };
        }) || []
      );
    },

    filterByItem(data) {
      switch (data[0]) {
        case "sites":
          this.filter = ["site_id", data[1]];
          break;
        case "remove":
          this.filter = [];
          break;
      }
    },

    handleCreate(value) {
      this.create = value;
    },

    async createNew(data) {
      const org = JSON.parse(localStorage.getItem("organization"));
      const client_id = this.client ? this.client.id : "";

      const toSend = {
        org_id: org.id,
        country_id: data.Country?.id,
        name: data["Site Name"],
        description: "empty",
        product_ids: data.Products ? data.Products.map((el) => el.id) : [],
        users: data.Users ? data.Users.map((el) => el.id) : [],
      };

      if (this.getUserProfile.role.id === 9) toSend.client_id = client_id;
      else toSend.client_id = data.Client ? data.Client.id : "";

      if (this.getUserProfile.role.id === 9)
        toSend.users.push(this.getUserProfile.id);

      try {
        const response = await axiosInstance.post(`manage-site`, toSend);
        if (response) {
          this.handleAlert(response.data.message);
          this.getData(1);
        }
      } catch (e) {
        this.handleAlert("Something went wrong");
      }

      this.create = false;
    },

    handleAlert(data) {
      this.emitter.emit("alert", data);
    },

    handleOption(data) {
      this.selItem = data[1];
      switch (data[0]) {
        case "see":
          this.$router.push({
            name: `equipment`,
            params: { prodid: this.selItem },
          });
          break;

        default:
          break;
      }
    },

    handleAction(data) {
      if (data.name === "delete selected") {
        data.data.forEach(async (element) => {
          await axiosInstance
            .post(`site/delete`, {
              site_id: element,
            })
            .finally(() => {
              this.getData(1);
            });
        });
      }
    },

    handleSearch(data) {
      this.search_key = data;
    },
  },
};
</script>